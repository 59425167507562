<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-img
          alt="Erro imagem"
          eager
          max-height="300"
          max-width="300"
          src="@/assets/pacman.png"
          transition="animate__animated animate__tada animate__slow"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <h1>Ops! Ocorreu um erro inesperado</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <router-link to="/">Página Inicial</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Erro"
};
</script>
